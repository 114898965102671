.ng-select .ng-arrow {
  display: none !important;
}

@mixin custom-arrow {
  display: inline-block;
  height: 8px;
  width: 8px !important;
  transform-origin: center center;
  border-top: 1px solid #999999 !important;
  border-left: 1px solid #999999 !important;
  opacity: 1;
  margin: 0 7px 0 10px;
  right: 3px;
  border-bottom-right-radius: 15px;
}
.ng-arrow-wrapper {
  @include custom-arrow;
  transform: rotate(225deg);
  top: -3px;
  right: 3px;
}
.ng-select-opened .ng-arrow-wrapper {
  @include custom-arrow;
  transform: rotate(45deg);
  top: 3px;
  right: 3px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #686e75 !important;
}
.ng-select.ng-select-single .ng-select-container {
  height: 36px;
  bottom: 1px;
}

.invalid-required.ng-select.is-invalid .ng-select-container {
  border-color: red !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("/assets/svg/warning.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.normal-ng-select .ng-select-container {
  border-radius: 4px !important;
  border: 1px solid #A4A4A4 !important;
  background-color: #ffffff !important;
}

.contract {
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
  }
} 

.time-select-hide {
  .ng-select .ng-arrow-wrapper {
    display: none;
  }
}
.custom-box-search {
  .ng-select.ng-select-single .ng-select-container {
    background: transparent !important; 
  }
}

.custom-box-read-only {
  .ng-arrow-wrapper {
    display: none
  }
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
    box-shadow: none;
    border: none;
    border-radius: unset;
    background-color: transparent;
    border-bottom: 1px solid lightgray;
    bottom: 0;
  }
} 

.custom-placeholder {
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
   font-weight: 200;
  }
}